import { Box, Paper } from '@mui/material';

export const NOT_FOUND_TYPES = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  ORGANIZATION_NOT_FOUND: 'ORGANIZATION_NOT_FOUND',
};

function NotFound(): JSX.Element {
  return (
    <Box>
      <Paper>test</Paper>
    </Box>
  );
}

export default NotFound;
