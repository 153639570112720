import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Loading from 'modules/auth/loading.container';
import { LoggedInState } from 'types/auth';
import { ROUTES } from 'utilities/routes';
import { auth } from 'utilities/firebase';
import { getCurrentUser, setLoggedInState } from 'modules/auth/auth.slice';
import { useAppDispatch } from 'store';

function LoadingRoutes(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!isLoading) {
      (async () => {
        const { currentUser } = auth;

        if (currentUser) {
          const response = await dispatch(getCurrentUser());

          if (response.type === getCurrentUser.rejected.toString()) {
            dispatch(setLoggedInState(LoggedInState.LOGGED_OUT));

            navigate(ROUTES.NOT_FOUND);
            return;
          }

          dispatch(setLoggedInState(LoggedInState.LOGGED_IN));
        }
      })();
    }
  }, [dispatch, navigate, isLoading]);

  return (
    <Routes>
      <Route path={ROUTES.LOADING} element={<Loading />} />
      <Route path="*" element={<Navigate to={ROUTES.LOADING} />} />
    </Routes>
  );
}

export default LoadingRoutes;
