/* eslint-disable max-len */
import api from 'middleware/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Organization, OrganizationUsersResponse } from 'types/organizations';
import { User } from 'types/users';

export interface OrganizationsState {
  isLoadingOrg: boolean;
  isLoadingOrgs: boolean;
  isLoadingOrgUsers: boolean;
  organization: Organization | null;
  organizations: Organization[] | null;
  organizationUsers: User[] | null;
}

const initialState: OrganizationsState = {
  isLoadingOrg: false,
  isLoadingOrgs: false,
  isLoadingOrgUsers: false,
  organization: null,
  organizations: null,
  organizationUsers: null,
};

export const getOrganizations = createAsyncThunk('organization/getOrganizations', async () => {
  const response = await api('organizations', { method: 'GET' }, true) as Organization[];

  return response;
});

export const getOrganization = createAsyncThunk('organization/getOrganization', async (organizationId: string) => {
  const response = await api(`organizations/${organizationId}`, { method: 'GET' }, true) as Organization;

  return response;
});

export const getOrganizationUsers = createAsyncThunk('organization/getOrganizationUsers', async (organizationId: string) => {
  const response = await api(`organizations/${organizationId}/users`, { method: 'GET' }, true) as OrganizationUsersResponse;

  return response;
});

export const createOrganization = createAsyncThunk('organizations/createOrganization', async (org: Organization) => {
  const response = await api('organizations', { method: 'POST', body: org }, true) as Organization;

  return response;
});

export const updateOrganization = createAsyncThunk('organizations/updateOrganizations', async (org: Organization) => {
  const response = await api(`organizations/${org.organizationId as string}`, { method: 'PUT', body: org }, true) as Organization;

  return response;
});

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizations.pending, (state) => { state.isLoadingOrgs = true; })
      .addCase(getOrganizations.fulfilled, (state, { payload }) => {
        state.isLoadingOrgs = false;
        state.organizations = payload;
      })
      .addCase(getOrganizations.rejected, (state) => {
        state.isLoadingOrgs = false;
      })
      .addCase(getOrganization.pending, (state) => { state.isLoadingOrg = true; })
      .addCase(getOrganization.fulfilled, (state, { payload }) => {
        state.isLoadingOrg = false;
        state.organization = payload;
      })
      .addCase(getOrganization.rejected, (state) => {
        state.isLoadingOrg = false;
      })
      .addCase(getOrganizationUsers.pending, (state) => { state.isLoadingOrgUsers = true; })
      .addCase(getOrganizationUsers.fulfilled, (state, { payload }) => {
        state.isLoadingOrgUsers = false;
        state.organizationUsers = payload.users;
      })
      .addCase(getOrganizationUsers.rejected, (state) => {
        state.isLoadingOrgUsers = false;
      })
      .addCase(createOrganization.pending, (state) => { state.isLoadingOrg = true; })
      .addCase(createOrganization.fulfilled, (state, { payload }) => {
        state.isLoadingOrg = false;
        state.organization = payload;
      })
      .addCase(createOrganization.rejected, (state) => {
        state.isLoadingOrg = false;
      })
      .addCase(updateOrganization.pending, (state) => { state.isLoadingOrg = true; })
      .addCase(updateOrganization.fulfilled, (state, { payload }) => {
        state.isLoadingOrg = false;
        state.organization = payload;
      })
      .addCase(updateOrganization.rejected, (state) => {
        state.isLoadingOrg = false;
      });
  },
});

export default organizationSlice.reducer;
