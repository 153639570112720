import { Box, styled } from '@mui/material';
import { StanleySVGIcon } from '@project-stanley/cap-management-components';

const StyledStanleySVGIcon = styled(StanleySVGIcon)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

function Loading(): JSX.Element {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" width="100%">
      <StyledStanleySVGIcon className="loading-logo" height="6rem" name="black-logo" width="10rem" />
    </Box>
  );
}

export default Loading;
