import { ExitToApp, Menu, PowerSettingsNew } from '@mui/icons-material';

import { DrawerItem } from 'types/drawer';
import { ROUTES } from 'utilities/routes';

export const SUPER_ADMIN_DRAWER_ITEMS: DrawerItem[] = [
  {
    icon: Menu,
    path: ROUTES.ORGANIZATIONS,
    text: 'Organizations',
  },
];

export const ADMIN_DRAWER_ITEMS: DrawerItem[] = [
  {
    icon: Menu,
    path: ROUTES.ORGANIZATIONS,
    text: 'Home',
  },
];

export const SECONDARY_DRAWER_ITEMS: DrawerItem[] = [
  {
    icon: ExitToApp,
    text: 'Back To App',
    onClick: () => {
      if (process.env.REACT_APP_MANAGEMENT_APP_URL) window.location.href = process.env.REACT_APP_MANAGEMENT_APP_URL;
    },
  },
  {
    icon: PowerSettingsNew,
    text: 'Logout',
  },
];
