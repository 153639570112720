import { Button, FormControl, TextField } from '@mui/material';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Container, Item } from '@project-stanley/cap-management-components';
import { makeStyles } from '@mui/styles';

import { SignInUser } from 'types/auth';

interface SignInFormProps {
  isLoading: boolean;

  onSubmit: (signInUser: SignInUser) => void;
}

function SignInForm({ isLoading, onSubmit }: SignInFormProps): JSX.Element {
  const classes = useStyles();

  const [signInUser, setSignInUser] = useState<SignInUser>({ email: '', password: '' });

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => setSignInUser({ ...signInUser, [name]: value }),
    [signInUser, setSignInUser],
  );

  const handleSignIn = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSubmit(signInUser);
    },
    [signInUser, onSubmit],
  );

  return (
    <form onSubmit={handleSignIn}>
      <Container direction="column" spacing={2}>
        <Item>
          <FormControl className={classes.formControl}>
            <TextField
              disabled={isLoading}
              fullWidth
              name="email"
              placeholder="E-mail Address"
              value={signInUser.email}
              variant="outlined"
              onChange={handleInputChange}
            />
          </FormControl>
        </Item>
        <Item>
          <FormControl className={classes.formControl}>
            <TextField
              disabled={isLoading}
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              value={signInUser.password}
              variant="outlined"
              onChange={handleInputChange}
            />
          </FormControl>
        </Item>
        <Item>
          <Button className={classes.signInBtn} color="primary" disabled={isLoading} type="submit" variant="contained">
            Sign In
          </Button>
        </Item>
      </Container>
    </form>
  );
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  signInBtn: {
    maxWidth: '10rem',
  },
}));

export default SignInForm;
