const REQUIRED = 'REQUIRED';

const INPUT_ERROR_MESSAGES = {
  [REQUIRED]: 'This field is required',
};

const INPUT_VALIDATORS = {
  [REQUIRED]: 'required',
};

export { INPUT_ERROR_MESSAGES, INPUT_VALIDATORS };
