import { Navigate, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import NotFound from 'modules/notFound/notFound.container';
import Organization from 'modules/organizations/organization.container';
import Organizations from 'modules/organizations/organizations.container';
import User from 'modules/users/user.container';
import { ROUTES, getOrganizationIdFromRoute } from 'utilities/routes';
import { selectUser } from 'modules/auth/auth.selectors';

function PrivateRoutes(): JSX.Element {
  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector(selectUser);
  const isOrgPage = useMatch(`${ROUTES.ORGANIZATIONS}/*`);
  const isNotFoundPage = useMatch(ROUTES.NOT_FOUND);

  useEffect(() => {
    if (!user || (!user.isOrgAdmin && !user.isSuperAdmin)) {
      if (!isNotFoundPage) navigate(ROUTES.NOT_FOUND);
      return;
    }

    if (user.isSuperAdmin) return;

    if (isOrgPage) {
      const organizationId = getOrganizationIdFromRoute(location.pathname);

      if (organizationId !== user.organizationId.toString()) {
        navigate(`${ROUTES.ORGANIZATIONS}/${user.organizationId}`);
      }
    }
  }, [location, navigate, isNotFoundPage, isOrgPage, user]);

  return (
    <Routes>
      <Route element={<Organizations />} path={ROUTES.ORGANIZATIONS} />
      <Route element={<Organization />} path={ROUTES.ORGANIZATION} />
      <Route element={<User />} path={`${ROUTES.ORGANIZATION}${ROUTES.USER}`} />
      <Route element={<NotFound />} path={ROUTES.NOT_FOUND} />
      <Route path="*" element={<Navigate to={ROUTES.ORGANIZATIONS} />} />
    </Routes>
  );
}

export default PrivateRoutes;
