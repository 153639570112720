/* eslint-disable max-len */
import { GAEvent } from 'types/analytics';
import { analytics } from 'utilities/firebase';
import { logEvent } from 'firebase/analytics';

export const logGAEvent = (event: GAEvent): void => {
  logEvent(analytics, event.action, event);
};

export const GA_OBJECT_FIELDS = {
  USER_ID: 'userId',
  USER_PROPERTIES: 'user_properties',
};

export const CUSTOM_GA_DIMENSIONS = {
  USER_EMAIL: 'userEmail',
  USER_IDENTIFIER: 'userIdentifier',
};

export const GA_HIT_TYPE = {
  PAGE_VIEW: 'pageview',
};

export const GA_CATEGORIES = {};

export const GA_ACTIONS = {};
