import { Organization } from 'types/organizations';
import { RootState } from 'store';
import { User } from 'types/users';

export const selectOrganizationsIsLoading = (state: RootState): boolean => state.organizations.isLoadingOrgs;

export const selectOrganizationIsLoading = (state: RootState): boolean => state.organizations.isLoadingOrg;

export const selectOrganizationUsersIsLoading = (state: RootState): boolean => state.organizations.isLoadingOrgUsers;

export const selectOrganizations = (state: RootState): Organization[] | null => state.organizations.organizations;

export const selectOrganization = (state: RootState): Organization | null => state.organizations.organization;

export const selectOrganizationUsers = (state: RootState): User[] | null => state.organizations.organizationUsers;
