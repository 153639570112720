import { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { TextValidator } from 'react-material-ui-form-validator';

import { FormUser, UserFormFields } from 'types/users';
import { INPUT_ERROR_MESSAGES, INPUT_VALIDATORS } from 'utilities/validation';

interface UserFormProps {
  isLoading: boolean;
  user: FormUser;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StyledContainer = styled(Container)({
  alignItems: 'center',
});

function UserForm({ isLoading, user, onChange }: UserFormProps): JSX.Element {
  return (
    <StyledContainer spacing={2}>
      <Item xs={12} sm={6}>
        <TextValidator
          disabled={isLoading || Boolean(user.userId)}
          errorMessages={[INPUT_ERROR_MESSAGES.REQUIRED]}
          fullWidth
          label="Email"
          name={UserFormFields.EMAIL}
          size="small"
          validators={[INPUT_VALIDATORS.REQUIRED]}
          value={user.email}
          variant="outlined"
          onChange={onChange}
        />
      </Item>
      {user.userId && (
        <Item xs={12} sm={6}>
          <TextValidator
            disabled={isLoading}
            errorMessages={[INPUT_ERROR_MESSAGES.REQUIRED]}
            fullWidth
            label="Title"
            name={UserFormFields.TITLE}
            size="small"
            validators={[INPUT_VALIDATORS.REQUIRED]}
            value={user.title}
            variant="outlined"
            onChange={onChange}
          />
        </Item>
      )}
      {!user.userId && (
        <Item xs={12} sm={6}>
          <TextValidator
            disabled={isLoading}
            errorMessages={[INPUT_ERROR_MESSAGES.REQUIRED]}
            fullWidth
            label="Password"
            name={UserFormFields.PASSWORD}
            size="small"
            validators={[INPUT_VALIDATORS.REQUIRED]}
            value={user.password}
            variant="outlined"
            onChange={onChange}
          />
        </Item>
      )}
      <Item xs={12} sm={6}>
        <TextValidator
          disabled={isLoading}
          errorMessages={[INPUT_ERROR_MESSAGES.REQUIRED]}
          fullWidth
          label="First Name"
          name={UserFormFields.FIRST_NAME}
          size="small"
          validators={[INPUT_VALIDATORS.REQUIRED]}
          value={user.firstName}
          variant="outlined"
          onChange={onChange}
        />
      </Item>
      <Item xs={12} sm={6}>
        <TextValidator
          disabled={isLoading}
          errorMessages={[INPUT_ERROR_MESSAGES.REQUIRED]}
          fullWidth
          label="Last Name"
          name={UserFormFields.LAST_NAME}
          size="small"
          validators={[INPUT_VALIDATORS.REQUIRED]}
          value={user.lastName}
          variant="outlined"
          onChange={onChange}
        />
      </Item>
      {!user.userId && (
        <Item xs={6}>
          <TextValidator
            disabled={isLoading}
            errorMessages={[INPUT_ERROR_MESSAGES.REQUIRED]}
            fullWidth
            label="Title"
            name={UserFormFields.TITLE}
            size="small"
            validators={[INPUT_VALIDATORS.REQUIRED]}
            value={user.title}
            variant="outlined"
            onChange={onChange}
          />
        </Item>
      )}
      <Item xs={12}>
        <Container spacing={2}>
          <Item sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.isActive}
                  color="primary"
                  disabled={isLoading}
                  name={UserFormFields.IS_ACTIVE}
                  onChange={onChange}
                />
              }
              label="Active"
            />
          </Item>
          <Item sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.isOrgAdmin}
                  color="primary"
                  disabled={isLoading}
                  name={UserFormFields.IS_ORG_ADMIN}
                  onChange={onChange}
                />
              }
              label="Organization Admin"
            />
          </Item>
        </Container>
      </Item>
    </StyledContainer>
  );
}

export default UserForm;
