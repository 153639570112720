import { Divider, List } from '@mui/material';
import { StanleyConfirmDialog, StanleyDrawerListItem } from '@project-stanley/cap-management-components';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ADMIN_DRAWER_ITEMS, SECONDARY_DRAWER_ITEMS, SUPER_ADMIN_DRAWER_ITEMS } from 'utilities/drawer';
import { LoggedInState } from 'types/auth';
import { auth } from 'utilities/firebase';
import { selectUser } from 'modules/auth/auth.selectors';
import { setLoggedInState } from 'modules/auth/auth.slice';

interface DrawerItemsProps {
  isDrawerOpen?: boolean;
}

function DrawerItems({ isDrawerOpen }: DrawerItemsProps): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);

  const primaryDrawerItems = useMemo(() => {
    if (!user) return [];

    return user.isSuperAdmin ? SUPER_ADMIN_DRAWER_ITEMS : ADMIN_DRAWER_ITEMS;
  }, [user]);

  const handleNavigate = useCallback(
    (path: string) => {
      if (!user) return;

      if (!user.isSuperAdmin) {
        switch (path) {
          default:
            navigate(`${path}/${user.organizationId}`);
            return;
        }
      }

      navigate(path);
    },
    [navigate, user],
  );

  const handleLogout = useCallback(() => {
    dispatch(setLoggedInState(LoggedInState.LOGGED_OUT));

    auth.signOut();
  }, [dispatch]);

  const handleToggleConfirmLogout = useCallback(() => {
    setConfirmLogoutOpen(!confirmLogoutOpen);
  }, [confirmLogoutOpen, setConfirmLogoutOpen]);

  return (
    <>
      <List style={{ flex: 1 }}>
        {primaryDrawerItems.map((item) => (
          <StanleyDrawerListItem
            key={item.text}
            isDrawerOpen={Boolean(isDrawerOpen)}
            item={{ ...item, onClick: () => handleNavigate(item.path || '') }}
          />
        ))}
      </List>
      <Divider />
      <List>
        {SECONDARY_DRAWER_ITEMS.map((item) => (
          <StanleyDrawerListItem
            key={item.text}
            isDrawerOpen={Boolean(isDrawerOpen)}
            item={{ ...item, onClick: item.onClick || handleToggleConfirmLogout }}
          />
        ))}
      </List>
      <StanleyConfirmDialog
        cancelText="Cancel"
        confirmText="Logout"
        contentText="Are you sure you want to logout?"
        open={confirmLogoutOpen}
        title="Logout?"
        onClose={handleToggleConfirmLogout}
        onConfirm={handleLogout}
      />
    </>
  );
}

export default DrawerItems;
