import { Box, Paper, styled } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { Container, StanleyTable, StanleyTableToolbar } from '@project-stanley/cap-management-components';
import { isNil } from 'lodash';
import { useAppDispatch } from 'store';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import { Organization } from 'types/organizations';
import { ROUTES } from 'utilities/routes';
import { TableRowClick } from 'types/table';
import { getOrganizations } from 'modules/organizations/organizations.slice';
import { selectOrganizations, selectOrganizationsIsLoading } from 'modules/organizations/organizations.selectors';

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function Organizations(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const organizations = useSelector(selectOrganizations);
  const isLoading = useSelector(selectOrganizationsIsLoading);

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      { title: 'Organization', field: 'name' },
      {
        field: 'isActive',
        title: 'Active',
        render: ({ isActive }: Organization) => (isActive ? <Check color="primary" /> : <Close color="error" />),
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: Organization) => {
      if (rowData && rowData.organizationId) {
        navigate(`${ROUTES.ORGANIZATIONS}/${rowData.organizationId}`);
      }
    },
    [navigate],
  );

  const renderToolbar = useCallback(
    (toolbarProps: Record<string, unknown>) => (
      <StanleyTableToolbar
        {...toolbarProps}
        actions={[{ text: 'Add Organization', onClick: () => navigate(`${ROUTES.ORGANIZATIONS}/new`) }]}
        title="Organizations"
      />
    ),
    [navigate],
  );

  if (isLoading || isNil(organizations)) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <Box margin={1}>
      <StyledPaper>
        <StanleyTable
          columns={columns}
          components={{
            Toolbar: renderToolbar,
          }}
          data={organizations}
          options={{ toolbar: true }}
          onRowClick={handleRowClick}
        />
      </StyledPaper>
    </Box>
  );
}

export default Organizations;
