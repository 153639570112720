import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from 'middleware/api';
import { AuthUser, LoggedInState } from 'types/auth';

interface AuthState {
  isLoadingUser: boolean;
  loggedInState: LoggedInState
  user: AuthUser | null
}

const initialState: AuthState = {
  isLoadingUser: false,
  loggedInState: LoggedInState.LOGGED_OUT,
  user: null,
};

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
  const response = await api('users/current', { method: 'GET' }, true) as AuthUser;

  return response;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedInState(state, action: PayloadAction<LoggedInState>) {
      state.loggedInState = action.payload;
    },
    resetUser(state) {
      state.loggedInState = LoggedInState.LOGGED_OUT;
      state.user = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCurrentUser.pending, (state) => { state.isLoadingUser = true; })
      .addCase(getCurrentUser.fulfilled, (state, { payload }) => {
        state.isLoadingUser = false;
        state.user = payload;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.isLoadingUser = false;
      });
    },
});

export const { setLoggedInState, resetUser } = authSlice.actions;

export default authSlice.reducer;
