import { Action, ThunkAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import authReducer from 'modules/auth/auth.slice';
import layoutReducer from 'modules/layout/layout.slice';
import organizationsReducer from 'modules/organizations/organizations.slice';
import usersReducer from 'modules/users/users.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    organizations: organizationsReducer,
    users: usersReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): ThunkDispatch<RootState, null | undefined, Action<string>> =>
  useDispatch<AppDispatch>();
