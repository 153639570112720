import { Box } from '@mui/material';
import { StanleyPermanentDrawer } from '@project-stanley/cap-management-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CacheBuster, { CacheBusterState } from 'modules/layout/cacheBuster.component';
import DrawerItems from 'modules/layout/drawer/drawerItems';
import Header, { HEADER_HEIGHT_REM } from 'modules/layout/header.component';
import LoadingRoutes from 'modules/layout/loadingRoutes.component';
import PrivateRoutes from 'modules/layout/privateRoutes.component';
import PublicRoutes from 'modules/layout/publicRoutes.component';
import ToastMessage from 'modules/layout/toastMessage';
import { LoggedInState } from 'types/auth';
import { selectInitialRoute } from 'modules/layout/layout.selectors';
import { selectLoginState } from 'modules/auth/auth.selectors';
import { setInitialRoute } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

function MainLayout(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loginState = useSelector(selectLoginState);
  const initialRoute = useSelector(selectInitialRoute);

  const [maxWidth, setMaxWidth] = useState('100%');

  useEffect(() => {
    if (loginState === LoggedInState.LOGGED_IN && initialRoute) {
      navigate(initialRoute);

      dispatch(setInitialRoute(''));
    }
  }, [dispatch, navigate, initialRoute, loginState]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: CacheBusterState) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <>
            <Box display="flex" height="100vh">
              {loginState === LoggedInState.LOGGED_OUT && <PublicRoutes />}
              {loginState === LoggedInState.LOGGED_IN && (
                <>
                  <Header />
                  <Box display="flex" flex="1" marginTop={`${HEADER_HEIGHT_REM}rem`}>
                    <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                      <StanleyPermanentDrawer onToggleDrawer={setMaxWidth}>
                        <DrawerItems />
                      </StanleyPermanentDrawer>
                    </Box>
                    <Box maxWidth={maxWidth} width="100%">
                      <PrivateRoutes />
                    </Box>
                  </Box>
                </>
              )}
              {loginState === LoggedInState.LOADING && <LoadingRoutes />}
            </Box>
            <ToastMessage />
          </>
        );
      }}
    </CacheBuster>
  );
}

export default MainLayout;
