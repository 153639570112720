import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ToastTypes } from 'types/layout';

interface LayoutState {
  initialRoute: string | null;
  showToast: boolean;
  toastMessage: string | null;
  toastType: ToastTypes | null;
}

export interface ShowToast {
  toastMessage: string | null;
  toastType: ToastTypes | null;
}

const initialState: LayoutState = {
  initialRoute: null,
  showToast: false,
  toastMessage: null,
  toastType: null,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    clearToast(state) {
      state.showToast = false;
    },
    showToast(state, action: PayloadAction<ShowToast>) {
      state.showToast = true;
      state.toastMessage = action.payload.toastMessage;
      state.toastType = action.payload.toastType;
    },
    setInitialRoute(state, action: PayloadAction<string>) {
      state.initialRoute = action.payload;
    },
  },
});

export const { setInitialRoute, showToast, clearToast } = layoutSlice.actions;

export default layoutSlice.reducer;
