import { RootState } from 'store/index';

import { ShowToast } from 'modules/layout/layout.slice';

export const selectShowToast = (state: RootState): boolean => state.layout.showToast;

export const selectToast = (state: RootState): ShowToast => {
  const { toastMessage, toastType } = state.layout;

  return { toastMessage, toastType };
};

export const selectInitialRoute = (state: RootState): string | null => state.layout.initialRoute;
