import { AppBar, Box, Toolbar, Typography, styled } from '@mui/material';
import { StanleyDrawerFooter, StanleyTemporaryDrawer } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';

import DrawerItems from 'modules/layout/drawer/drawerItems';

export const HEADER_HEIGHT_REM = 5.75;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: `${HEADER_HEIGHT_REM}rem`,
}));

const StyledToolbar = styled(Toolbar)({
  alignItems: 'initial',
  height: '100%',
});

const StyledBox = styled(Box)(({ theme }) => ({
  borderBottomRightRadius: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minWidth: '3rem',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textAlign: 'center',
}));

function Header(): JSX.Element {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawerOpen = useCallback(() => setIsDrawerOpen(!isDrawerOpen), [isDrawerOpen, setIsDrawerOpen]);

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar disableGutters>
        <StyledBox>
          <Typography>{format(new Date(), 'MMM')}</Typography>
          <Typography>{format(new Date(), 'd')}</Typography>
        </StyledBox>
        <Box flex="1" />
        <Box alignItems="center" display="flex" height="100%" sx={{ display: { sm: 'none', xs: 'flex' } }}>
          <StanleyDrawerFooter iconHeight="2rem" onToggleDrawer={handleToggleDrawerOpen} />
        </Box>
        <StanleyTemporaryDrawer isDrawerOpen={isDrawerOpen} onToggleDrawer={handleToggleDrawerOpen}>
          <DrawerItems />
        </StanleyTemporaryDrawer>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default Header;
