import { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { TextValidator } from 'react-material-ui-form-validator';

import { INPUT_ERROR_MESSAGES, INPUT_VALIDATORS } from 'utilities/validation';
import { Organization, OrganizationFormFields } from 'types/organizations';

interface OrganizationFormProps {
  isLoading: boolean;
  organization: Organization;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StyledContainer = styled(Container)({
  alignItems: 'center',
});

function OrganizationForm({ isLoading, organization, onChange }: OrganizationFormProps): JSX.Element {
  return (
    <StyledContainer spacing={2}>
      <Item xs={12} sm={6}>
        <TextValidator
          disabled={isLoading}
          errorMessages={[INPUT_ERROR_MESSAGES.REQUIRED]}
          fullWidth
          label="Organization Name"
          name={OrganizationFormFields.NAME}
          size="small"
          validators={[INPUT_VALIDATORS.REQUIRED]}
          value={organization.name}
          variant="outlined"
          onChange={onChange}
        />
      </Item>
      <Item sm={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={organization.isActive}
              color="primary"
              disabled={isLoading}
              name={OrganizationFormFields.IS_ACTIVE}
              onChange={onChange}
            />
          }
          label="Is Active?"
        />
      </Item>
    </StyledContainer>
  );
}

export default OrganizationForm;
