import { User } from 'types/users';

export interface Organization {
  organizationId?: string;
  name: string;
  isActive: boolean;
}

export enum OrganizationFormFields {
  NAME = 'name',
  IS_ACTIVE = 'isActive',
}

export interface OrganizationUsersResponse {
  users: User[]
}
