/* eslint-disable max-len */
import api from 'middleware/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { NewSubmitUser, UpdateSubmitUser, User } from 'types/users';

export interface UserState {
  isLoading: boolean;
  user: User | null;
}

const initialState: UserState = {
  isLoading: false,
  user: null,
};

export const getUser = createAsyncThunk('user/getUser', async (userId: string) => {
  const response = await api(`users/${userId}`, { method: 'GET' }, true) as User;

  return response;
});

export const createUser = createAsyncThunk('user/createUser', async (payload: NewSubmitUser) => {
  const response = await api('users', { method: 'POST', body: payload }, true) as User;

  return response;
});

export const updateUser = createAsyncThunk('user/updateUser', async (payload: UpdateSubmitUser) => {
  const response = await api(`users/${payload.userId}/profile`, { method: 'PUT', body: payload }, true) as User;

  return response;
});

export const updateUserRoles = createAsyncThunk('user/updateUserRoles', async (payload: { userId: string, roles: number[] }) => {
  const response = await api(`users/${payload.userId}/roles`, { method: 'POST', body: { roles: payload.roles } }, true) as User;

  return response;
});

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUser.pending, (state) => { state.isLoading = true; })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.user = payload;
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createUser.pending, (state) => { state.isLoading = true; })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.user = payload;
      })
      .addCase(createUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUser.pending, (state) => { state.isLoading = true; })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.user = payload;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUserRoles.pending, (state) => { state.isLoading = true; })
      .addCase(updateUserRoles.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.user = payload;
      })
      .addCase(updateUserRoles.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default usersSlice.reducer;
