const LOADING = '/loading';
const ORGANIZATIONS = '/organizations';
const SIGNIN = '/signin';
const SIGNUP = '/signup';
const NOT_FOUND = '/not-found';
const USERS = '/users';

const ORGANIZATION_ID = ':organizationId';
const USER_ID = ':userId';

export const ROUTE_PARAMS = {
  ORGANIZATION_ID,
  USER_ID,
};

export const ROUTES = {
  LOADING,
  NOT_FOUND,
  ORGANIZATIONS,
  ORGANIZATION: `${ORGANIZATIONS}/${ROUTE_PARAMS.ORGANIZATION_ID}`,
  USERS,
  USER: `${USERS}/${ROUTE_PARAMS.USER_ID}`,
  SIGNIN,
  SIGNUP,
};

export const getOrganizationIdFromRoute = (pathname: string): string | null => {
  const pathParts = pathname.split('/');

  if (pathParts.length < 3) return null;

  return pathParts[2];
};
