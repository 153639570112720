import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, StanleyPrimaryButton, StanleySecondaryButton } from '@project-stanley/cap-management-components';
import { Paper, styled } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { cloneDeep } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserForm from 'modules/users/userForm.component';
import { FormUser, NewSubmitUser, UpdateSubmitUser, UserFormFields } from 'types/users';
import { ROUTES } from 'utilities/routes';
import { ToastTypes } from 'types/layout';
import { createUser, getUser, updateUser, updateUserRoles } from 'modules/users/users.slice';
import { selectUserIsLoading } from 'modules/users/users.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

const INITIAL_USER_DATA: FormUser = {
  email: '',
  firstName: '',
  isActive: true,
  isOrgAdmin: false,
  lastName: '',
  title: '',
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}));

const StyledValidatorForm = styled(ValidatorForm)(({ theme }) => ({
  margin: theme.spacing(2),
}));

function User(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();

  const isLoading = useSelector(selectUserIsLoading);

  const [user, setUser] = useState<FormUser>(INITIAL_USER_DATA);

  useEffect(() => {
    if (userId && userId !== 'new') {
      (async () => {
        const response = await dispatch(getUser(userId));

        if (response.type === getUser.rejected.toString()) {
          dispatch(
            showToast({
              toastMessage: 'Failed to get User. Please try again.',
              toastType: ToastTypes.ERROR,
            }),
          );
        }

        setUser(response.payload as FormUser);
      })();
    }
  }, [dispatch, userId]);

  const isNewUser = useMemo(() => user && !user.userId, [user]);

  const handleSubmit = useCallback(async () => {
    let response;

    const payload = cloneDeep(user);

    payload.roles = payload.isOrgAdmin ? [2] : [];

    delete payload.isOrgAdmin;

    if (isNewUser && organizationId) {
      (payload as NewSubmitUser).organizationId = Number(organizationId);

      response = await dispatch(createUser(payload as NewSubmitUser));
    } else {
      delete payload.password;

      response = await dispatch(updateUser(payload as UpdateSubmitUser));
    }

    if (response.type === createUser.rejected.toString() || response.type === updateUser.rejected.toString()) {
      const toastMessage = isNewUser
        ? 'Unable to create user. Please try again.'
        : 'Unable to save user. Please try again.';

      dispatch(
        showToast({
          toastMessage,
          toastType: ToastTypes.ERROR,
        }),
      );
      return;
    }

    let toastMessage = isNewUser ? 'Created user successfully.' : 'Saved user successfully.';
    const rolesResponse = await dispatch(updateUserRoles({ userId: payload.userId as string, roles: payload.roles }));

    if (rolesResponse.type === updateUserRoles.rejected.toString()) {
      toastMessage += 'But, failed to save roles. Please try again.';

      dispatch(
        showToast({
          toastMessage,
          toastType: ToastTypes.ERROR,
        }),
      );
      return;
    }

    dispatch(
      showToast({
        toastMessage,
        toastType: ToastTypes.SUCCESS,
      }),
    );

    if (isNewUser && organizationId) navigate(`${ROUTES.ORGANIZATIONS}/${organizationId}`);
  }, [dispatch, navigate, isNewUser, organizationId, user]);

  const handleOrgChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { checked, name, value } = target;

      if (name === UserFormFields.IS_ACTIVE || name === UserFormFields.IS_ORG_ADMIN) {
        setUser({ ...user, [name]: checked });
        return;
      }

      setUser({ ...user, [name]: value });
    },
    [user, setUser],
  );

  return (
    <StyledValidatorForm onSubmit={handleSubmit}>
      <StyledPaper>
        <UserForm isLoading={isLoading} user={user} onChange={handleOrgChange} />
      </StyledPaper>
      <Container justifyContent="space-between">
        <StanleySecondaryButton disabled={isLoading} onClick={() => navigate(-1)}>
          Cancel
        </StanleySecondaryButton>
        <StanleyPrimaryButton disabled={isLoading} type="submit" onClick={() => {}}>
          {isNewUser ? 'Create' : 'Update'}
        </StanleyPrimaryButton>
      </Container>
    </StyledValidatorForm>
  );
}

export default User;
