export interface User {
  userId: number;
  firebaseUserId: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  isActive: boolean;
  organizationId: number;
  isSuperAdmin: boolean;
  isOrgAdmin: boolean;
  roles: number[];
}

export interface FormUser {
  userId?: string;
  email: string;
  firstName: string;
  isActive: boolean;
  isOrgAdmin?: boolean;
  lastName: string;
  password?: string;
  title: string;
  roles?: number[];
}

export interface NewSubmitUser {
  email: string;
  firstName: string;
  isActive: boolean;
  lastName: string;
  organizationId: number;
  password: string;
  title: string;
  roles: number[];
}

export interface UpdateSubmitUser {
  userId: string;
  email: string;
  firstName: string;
  isActive: boolean;
  lastName: string;
  title: string;
  roles: number[];
}

export enum UserFormFields {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  IS_ACTIVE = 'isActive',
  IS_ORG_ADMIN = 'isOrgAdmin',
  LAST_NAME = 'lastName',
  PASSWORD = 'password',
  TITLE = 'title',
}
