import { Navigate, Route, Routes } from 'react-router-dom';

// import NotFound from 'modules/notFound/notFound.container';
import SignIn from 'modules/auth/signIn.container';
import { ROUTES } from 'utilities/routes';
// import SignUp from 'modules/auth/signUp.container';

function PublicRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<SignIn />} path={ROUTES.SIGNIN} />
      {/* <Route element={<SignUp />} path={ROUTES.SIGNUP} /> */}
      {/* <Route element={<NotFound />} path={ROUTES.NOT_FOUND} /> */}
      <Route path="*" element={<Navigate to={ROUTES.SIGNIN} />} />
    </Routes>
  );
}

export default PublicRoutes;
