import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ToastTypes } from 'types/layout';
import { clearToast } from 'modules/layout/layout.slice';
import { selectShowToast, selectToast } from 'modules/layout/layout.selectors';
import { useAppDispatch } from 'store';

function ToastMessage(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const showToast = useSelector(selectShowToast);
  const toast = useSelector(selectToast);

  const handleClose = useCallback(() => {
    dispatch(clearToast());
  }, [dispatch]);

  return (
    <Snackbar
      action={
        <IconButton onClick={handleClose} size="large">
          <Close />
        </IconButton>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      // autoHideDuration={5000}
      ContentProps={{
        className: classNames(
          { [classes.error]: toast.toastType === ToastTypes.ERROR },
          { [classes.success]: toast.toastType === ToastTypes.SUCCESS },
        ),
      }}
      message={toast.toastMessage}
      open={showToast}
      onClose={handleClose}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}));

export default ToastMessage;
