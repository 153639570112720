/* eslint-disable react/no-unstable-nested-components */
import { Check, Close } from '@mui/icons-material';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'utilities/routes';
import { StanleyTable, StanleyTableToolbar, TableRowClick } from '@project-stanley/cap-management-components';
import { User } from 'types/users';
import { getOrganizationUsers } from 'modules/organizations/organizations.slice';
import {
  selectOrganizationUsers,
  selectOrganizationUsersIsLoading,
} from 'modules/organizations/organizations.selectors';
import { useAppDispatch } from 'store';

interface UsersTableProps {
  organizationId?: string;
  showToolbar?: boolean;
}

function UsersTable({ organizationId, showToolbar }: UsersTableProps): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const organizationUsers = useSelector(selectOrganizationUsers);
  const isLoading = useSelector(selectOrganizationUsersIsLoading);

  useEffect(() => {
    if (organizationId) dispatch(getOrganizationUsers(organizationId));
  }, [dispatch, organizationId]);

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        customSort: (rowA: User, rowB: User) =>
          `${rowA.firstName} ${rowA.lastName}`.localeCompare(`${rowB.firstName} ${rowB.lastName}`),
        render: ({ firstName, lastName }: User) => `${firstName} ${lastName}`,
      },
      {
        field: 'isOrgAdmin',
        title: 'Org Admin',
        render: ({ isOrgAdmin }: User) => (isOrgAdmin ? <Check color="primary" /> : <Close color="error" />),
      },
      {
        field: 'isActive',
        title: 'Active',
        render: ({ isActive }: User) => (isActive ? <Check color="primary" /> : <Close color="error" />),
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: User) => {
      if (rowData && rowData.organizationId) {
        navigate(`${ROUTES.ORGANIZATIONS}/${rowData.organizationId}${ROUTES.USERS}/${rowData.userId}`);
      }
    },
    [navigate],
  );

  const handleAddUser = useCallback(() => {
    if (organizationId) {
      navigate(`${ROUTES.ORGANIZATIONS}/${organizationId}${ROUTES.USERS}/new`);
    }
  }, [navigate, organizationId]);

  return (
    <StanleyTable
      columns={columns}
      components={{
        Toolbar: (toolbarProps: Record<string, unknown>) => (
          <StanleyTableToolbar
            {...toolbarProps}
            actions={[{ text: 'Add User', onClick: handleAddUser }]}
            title="Users"
          />
        ),
      }}
      data={organizationUsers || []}
      isLoading={isLoading}
      options={{ toolbar: Boolean(showToolbar) }}
      onRowClick={handleRowClick}
    />
  );
}

export default UsersTable;
