import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, StanleyPrimaryButton, StanleySecondaryButton } from '@project-stanley/cap-management-components';
import { Paper, styled } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isNil } from 'lodash';
import { useAppDispatch } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import OrganizationForm from 'modules/organizations/organizationForm.container';
import UsersTable from 'modules/organizations/users/usersTable.component';
import { OrganizationFormFields, Organization as OrganizationType } from 'types/organizations';
import { ROUTES } from 'utilities/routes';
import { ToastTypes } from 'types/layout';
import { createOrganization, getOrganization, updateOrganization } from 'modules/organizations/organizations.slice';
import { selectIsSuperAdmin } from 'modules/auth/auth.selectors';
import { selectOrganizationIsLoading } from 'modules/organizations/organizations.selectors';
import { showToast } from 'modules/layout/layout.slice';

const INITIAL_ORG_DATA: OrganizationType = {
  isActive: false,
  name: '',
};

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  overflowY: 'scroll',
  padding: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}));

const StyledValidatorForm = styled(ValidatorForm)(({ theme }) => ({
  margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

function Organization(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { organizationId } = useParams<{ organizationId: string }>();

  const isLoading = useSelector(selectOrganizationIsLoading);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const [organization, setOrganization] = useState<OrganizationType>(INITIAL_ORG_DATA);

  useEffect(() => {
    if (organizationId && organizationId !== 'new') {
      (async () => {
        const response = await dispatch(getOrganization(organizationId));

        if (response.type === getOrganization.rejected.toString()) {
          dispatch(
            showToast({
              toastMessage: 'Failed to get Organization. Please try again.',
              toastType: ToastTypes.ERROR,
            }),
          );
        }

        setOrganization(response.payload as OrganizationType);
      })();
    }
  }, [dispatch, organizationId]);

  const isNewOrganization = useMemo(() => organization && !organization.organizationId, [organization]);

  const handleSubmit = useCallback(async () => {
    let response;

    if (isNewOrganization) {
      response = await dispatch(createOrganization(organization));
    } else {
      response = await dispatch(updateOrganization(organization));
    }

    if (
      response.type === createOrganization.rejected.toString() ||
      response.type === updateOrganization.rejected.toString()
    ) {
      const toastMessage = isNewOrganization
        ? 'Unable to create organization. Please try again.'
        : 'Unable to save organization. Please try again.';

      dispatch(
        showToast({
          toastMessage,
          toastType: ToastTypes.ERROR,
        }),
      );
      return;
    }

    const toastMessage = isNewOrganization ? 'Updated to create organization.' : 'Saved organization successfully.';

    dispatch(
      showToast({
        toastMessage,
        toastType: ToastTypes.SUCCESS,
      }),
    );

    navigate(`${ROUTES.ORGANIZATIONS}`);
  }, [dispatch, navigate, isNewOrganization, organization]);

  const handleOrgChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { checked, name, value } = target;

      if (name === OrganizationFormFields.IS_ACTIVE) {
        setOrganization({ ...organization, [name]: checked });
        return;
      }

      setOrganization({ ...organization, [name]: value });
    },
    [organization, setOrganization],
  );

  if (isLoading || isNil(organization)) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <StyledValidatorForm onSubmit={handleSubmit}>
      <StyledPaper>
        <OrganizationForm isLoading={isLoading} organization={organization} onChange={handleOrgChange} />
      </StyledPaper>
      {!isNewOrganization && (
        <StyledPaper>
          <UsersTable organizationId={organizationId} showToolbar />
        </StyledPaper>
      )}
      <Container justifyContent={isSuperAdmin ? 'space-between' : 'flex-end'}>
        {isSuperAdmin && (
          <StanleySecondaryButton disabled={isLoading} onClick={() => navigate(-1)}>
            Cancel
          </StanleySecondaryButton>
        )}
        <StanleyPrimaryButton disabled={isLoading} type="submit" onClick={() => {}}>
          {isNewOrganization ? 'Create' : 'Update'}
        </StanleyPrimaryButton>
      </Container>
    </StyledValidatorForm>
  );
}

export default Organization;
