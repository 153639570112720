import makeStyles from '@mui/styles/makeStyles';
import { AuthError, browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { Box, Paper, styled } from '@mui/material';
import { Container, Item, StanleySVGIcon } from '@project-stanley/cap-management-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignInForm from 'modules/auth/signInForm.component';
import { ROUTES } from 'utilities/routes';
import { SignInUser } from 'types/auth';
import { ToastTypes } from 'types/layout';
import { auth } from 'utilities/firebase';
import { selectUser } from 'modules/auth/auth.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

const StyledStanleySVGIcon = styled(StanleySVGIcon)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginLeft: 'auto',
  marginRight: 'auto',
}));

function SignIn(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const [isSigningIn, setIsSigningIn] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(ROUTES.LOADING);
    }
  }, [navigate, user]);

  const handleSignIn = useCallback(
    async ({ email, password }: SignInUser) => {
      setIsSigningIn(true);

      try {
        await setPersistence(auth, browserSessionPersistence);
        await signInWithEmailAndPassword(auth, email, password);
      } catch (err) {
        dispatch(
          showToast({
            toastMessage: (err as AuthError).message,
            toastType: ToastTypes.ERROR,
          }),
        );
      }

      setIsSigningIn(false);
    },
    [dispatch, setIsSigningIn],
  );

  return (
    <Box alignItems="center" display="flex" justifyContent="center" width="100%">
      <Paper className={classes.paper}>
        <Container direction="column" spacing={2}>
          <Item>
            <StyledStanleySVGIcon height="6rem" name="black-logo" width="12rem" />
          </Item>
          <Item>
            <SignInForm isLoading={isSigningIn} onSubmit={handleSignIn} />
          </Item>
        </Container>
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '30rem',
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  signUpLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}));

export default SignIn;
